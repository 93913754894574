import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab9 = () => {
  return (
    <>
      <PageSubHeader title="Odstąpienie od Umowy sprzedaży" />
      <ol>
        <li>
          Klient będący Konsumentem oraz Przedsiębiorca z uprawnieniami
          Konsumenta, który zawarł Umowę sprzedaży, może w terminie 14 dni
          odstąpić od niej bez podawania przyczyny.
        </li>
        <li>
          Bieg terminu na odstąpienie od Umowy sprzedaży rozpoczyna się od
          chwili objęcia Towaru w posiadanie przez Konsumenta, Przedsiębiorcę z
          uprawnieniami Konsumenta lub wskazaną przez nich osobę trzecią inną
          niż przewoźnik. Konsument oraz Przedsiębiorca z uprawnieniami
          Konsumenta może odstąpić od Umowy sprzedaży składając Sprzedawcy
          oświadczenie o odstąpieniu. Oświadczenie to może zostać złożone
          przykładowo pisemnie na adres Sprzedawcy, tj.: MEMTECH Sp. z o.o. Sp.
          k., ul. Witolda Doroszewskiego 7 (01-318), Warszawa, lub też za
          pośrednictwem poczty elektronicznej na adres Sprzedawcy, tj.:
          serwis@memtech.pl. Oświadczenie można złożyć na formularzu, którego
          wzór został zamieszczony przez Sprzedawcę na Stronie Internetowej
          Sklepu pod adresem: Formularz odstąpienia. Do zachowania terminu
          wystarczy wysłanie oświadczenia przed jego upływem. Konsument oraz
          Przedsiębiorca z uprawnieniami Konsumenta może odstąpić od Umowy
          sprzedaży, składając Sprzedawcy oświadczenie o odstąpieniu za
          pośrednictwem formularza udostępnionego na stronie internetowej pod
          adresem: Elektroniczny Formularz odstąpienia. Do zachowania terminu
          wystarczy wysłanie oświadczenia przed jego upływem. Sprzedawca
          niezwłocznie potwierdza Konsumentowi oraz Przedsiębiorcy z
          uprawnieniami Konsumenta otrzymanie formularza złożonego za
          pośrednictwem strony internetowej.
        </li>
        <li>
          W przypadku odstąpienia od Umowy sprzedaży, jest ona uważana za
          niezawartą.
        </li>
        <li>
          Jeśli Konsument lub Przedsiębiorca z uprawnieniami Konsumenta złożył
          oświadczenie o odstąpieniu od Umowy sprzedaży zanim Sprzedawca przyjął
          jego ofertę, oferta przestaje wiązać.
        </li>
        <li>
          Sprzedawca ma obowiązek niezwłocznie, nie później niż w terminie 14
          dni od dnia otrzymania oświadczenia Konsumenta lub Przedsiębiorcy z
          uprawnieniami Konsumenta o odstąpieniu od Umowy sprzedaży, zwrócić mu
          wszystkie dokonane przez niego płatności, w tym koszt Dostawy Towaru
          do Konsumenta lub Przedsiębiorcy z uprawnieniami Konsumenta.
          Sprzedawca może wstrzymać się ze zwrotem płatności otrzymanych od
          Konsumenta lub Przedsiębiorcy z uprawnieniami Konsumenta do chwili
          otrzymania z powrotem Towaru lub dostarczenia przez Konsumenta lub
          Przedsiębiorcy z uprawnieniami Konsumenta dowodu odesłania Towaru, w
          zależności od tego, które zdarzenie nastąpi wcześniej.
        </li>
        <li>
          Jeżeli Konsument lub Przedsiębiorca z uprawnieniami Konsumenta
          korzystający z prawa do odstąpienia wybrał sposób dostarczenia Towaru
          inny niż najtańszy zwykły sposób Dostawy oferowany przez Sprzedawcę,
          Sprzedawca nie jest zobowiązany do zwrotu Konsumentowi lub
          Przedsiębiorcy z uprawnieniami Konsumenta poniesionych przez niego
          dodatkowych kosztów.
        </li>
        <li>
          Konsument lub Przedsiębiorca z uprawnieniami Konsumenta ma obowiązek
          zwrócić Towar Sprzedawcy niezwłocznie, jednak nie później niż w
          terminie 14 dni od dnia, w którym odstąpił od Umowy sprzedaży. Do
          zachowania terminu wystarczy odesłanie Towaru na adres Sprzedawcy
          przed upływem tego terminu.
        </li>
        <li>
          W wypadku odstąpienia Klient będący Konsumentem lub Przedsiębiorca z
          uprawnieniami Konsumenta ponosi tylko bezpośrednie koszty zwrotu.
        </li>
        <li>
          Jeśli ze względu na swój charakter Towar nie może zostać w zwykłym
          trybie odesłany pocztą, Sprzedawca informuje Konsumenta oraz
          Przedsiębiorcę z uprawnieniami Konsumenta o kosztach zwrotu rzeczy na
          Stronie Internetowej Sklepu.
        </li>
        <li>
          Konsument oraz Przedsiębiorca z uprawnieniami Konsumenta ponosi
          odpowiedzialność za zmniejszenie wartości Towaru będące wynikiem
          korzystania z niego w sposób wykraczający poza sposób konieczny do
          stwierdzenia charakteru, cech i funkcjonowania Towaru.
        </li>
        <li>
          Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego
          sposobu zapłaty, jakiego użył Konsument lub Przedsiębiorca z
          uprawnieniami Konsumenta chyba, że Konsument lub Przedsiębiorca z
          uprawnieniami Konsumenta wyraźnie zgodził się na inny sposób zwrotu,
          który nie wiąże się dla niego z żadnymi kosztami.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab9
