import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab11 = () => {
  return (
    <>
      <PageSubHeader title="Odpowiedzialność Klienta w zakresie zamieszczanych przez niego treści" />
      <ol>
        <li>
          Zamieszczając treści oraz udostępniając je Klient dokonuje
          dobrowolnego rozpowszechniania treści. Zamieszczane treści nie
          wyrażają poglądów Sprzedawcy i nie powinny być utożsamiane z jego
          działalnością. Sprzedawca nie jest dostawcą treści, a jedynie
          podmiotem, który zapewnia w tym celu odpowiednie zasoby
          teleinformatyczne.
        </li>
        <li>Klient oświadcza, że:</li>
        <ol>
          <li>
            jest uprawniony do korzystania z autorskich praw majątkowych, praw
            własności przemysłowej i/lub praw pokrewnych do – odpowiednio -
            utworów, przedmiotów praw własności przemysłowej (np. znaki
            towarowe) i/lub przedmiotów praw pokrewnych, które składają się na
            treści;
          </li>
          <li>
            umieszczenie oraz udostępnienie w ramach usług, o których mowa w §10
            Regulaminu, danych osobowych, wizerunku oraz informacji dotyczących
            osób trzecich nastąpiło w sposób legalny, dobrowolny oraz za zgodą
            osób, których one dotyczą;
          </li>
          <li>
            wyraża zgodę na wgląd do opublikowanych treści przez innych Klientów
            oraz Sprzedawcę, jak również upoważnia Sprzedawcę do ich
            wykorzystania nieodpłatnie zgodnie z postanowieniami niniejszego
            Regulaminu;
          </li>
          <li>
            wyraża zgodę na dokonywanie opracowań utworów w rozumieniu Ustawy o
            prawie autorskim i prawach pokrewnych.
          </li>
        </ol>
        <li>Klient nie jest uprawniony do:</li>
        <ol>
          <li>
            zamieszczania w ramach korzystania z usług, o których mowa w §10
            Regulaminu, danych osobowych osób trzecich oraz rozpowszechniania
            wizerunku osób trzecich bez wymaganego prawem zezwolenia lub zgody
            osoby trzeciej;
          </li>
          <li>
            zamieszczania w ramach korzystania z usług, o których mowa w §10
            Regulaminu, treści o charakterze reklamowym i/lub promocyjnym.
          </li>
        </ol>
        <li>
          Sprzedawca ponosi odpowiedzialność za zamieszczane przez Klientów
          treści pod warunkiem otrzymania powiadomienia zgodnie z §12
          Regulaminu.
        </li>
        <li>
          Zabronione jest zamieszczanie przez Klientów w ramach korzystania z
          usług, o których mowa w §10 Regulaminu, treści które mogłyby w
          szczególności:
        </li>
        <ol>
          <li>
            zostać zamieszczane w złej wierze, np. z zamiarem naruszenia dóbr
            osobistych osób trzecich;
          </li>
          <li>
            naruszać jakiekolwiek prawa osób trzecich, w tym prawa związane z
            ochroną praw autorskich i praw pokrewnych, ochroną praw własności
            przemysłowej, tajemnicą przedsiębiorstwa lub mające związek ze
            zobowiązaniami o zachowaniu poufności;
          </li>
          <li>
            posiadać charakter obraźliwy bądź stanowić groźbę skierowaną do
            innych osób, zawierałyby słownictwo naruszające dobre obyczaje (np.
            poprzez użycie wulgaryzmów lub określeń powszechnie uznawanych za
            obraźliwe);
          </li>
          <li>
            pozostawać w sprzeczności z interesem Sprzedawcy, tj. treści
            stanowiących materiały o charakterze reklamowym innego
            przedsiębiorcy lub produktu; treści nie związanych z działalnością
            Sprzedawcy; treści nieprawdziwych lub wprowadzających w błąd;
          </li>
          <li>
            naruszać w inny sposób postanowienia Regulaminu, dobre obyczaje,
            przepisy obowiązującego prawa, normy społeczne lub obyczajowe.
          </li>
        </ol>
        <li>
          W przypadku otrzymania powiadomienia zgodnie z §12 Regulaminu,
          Sprzedawca zastrzega sobie prawo do modyfikowania lub usuwania treści
          zamieszczanych przez Klientów w ramach korzystania przez nich z usług,
          o których mowa w §10 Regulaminu, w szczególności w odniesieniu do
          treści, co do których, opierając się na doniesieniach osób trzecich
          lub odpowiednich organów, stwierdzono, że mogą one stanowić naruszenie
          niniejszego Regulaminu lub obowiązujących przepisów prawa. Sprzedawca
          nie prowadzi bieżącej kontroli zamieszczanych treści.
        </li>
        <li>
          Klient wyraża zgodę na nieodpłatne wykorzystywanie przez Sprzedawcę
          umieszczonych przez niego treści w ramach Strony Internetowej Sklepu.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab11
