import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab13 = () => {
  return (
    <>
      <PageSubHeader title="Ochrona danych osobowych" />
      <ol>
        <li>
          Zasady ochrony Danych Osobowych zamieszczone są w Polityce
          prywatności.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab13
