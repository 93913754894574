import React from "react"

import {
  PolicyContentTab1,
  PolicyContentTab2,
  PolicyContentTab3,
  PolicyContentTab4,
  PolicyContentTab5,
  PolicyContentTab6,
  PolicyContentTab7,
  PolicyContentTab8,
  PolicyContentTab9,
  PolicyContentTab10,
  PolicyContentTab11,
  PolicyContentTab12,
  PolicyContentTab13,
  PolicyContentTab14,
  PolicyContentTab15,
} from "./PolicyContentTabs"

const PolicyContent = ({ tab }) => {
  return (
    <div className="privacy-policy__content">
      {tab === 1 && <PolicyContentTab1 />}
      {tab === 2 && <PolicyContentTab2 />}
      {tab === 3 && <PolicyContentTab3 />}
      {tab === 4 && <PolicyContentTab4 />}
      {tab === 5 && <PolicyContentTab5 />}
      {tab === 6 && <PolicyContentTab6 />}
      {tab === 7 && <PolicyContentTab7 />}
      {tab === 8 && <PolicyContentTab8 />}
      {tab === 9 && <PolicyContentTab9 />}
      {tab === 10 && <PolicyContentTab10 />}
      {tab === 11 && <PolicyContentTab11 />}
      {tab === 12 && <PolicyContentTab12 />}
      {tab === 13 && <PolicyContentTab13 />}
      {tab === 14 && <PolicyContentTab14 />}
      {tab === 15 && <PolicyContentTab15 />}
    </div>
  )
}

export default PolicyContent
