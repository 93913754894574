import React from "react"

const PolicyNav = ({ tab, setTab }) => {
  return (
    <div className="privacy-policy__nav">
      <button onClick={() => setTab(1)} className={tab === 1 ? "current" : ""}>
        Definicje
      </button>
      <button onClick={() => setTab(2)} className={tab === 2 ? "current" : ""}>
        Postanowienia ogólne i korzystanie ze Sklepu Internetowego
      </button>
      <button onClick={() => setTab(3)} className={tab === 3 ? "current" : ""}>
        Rejestracja
      </button>
      <button onClick={() => setTab(4)} className={tab === 4 ? "current" : ""}>
        Zamówienia
      </button>
      <button onClick={() => setTab(5)} className={tab === 5 ? "current" : ""}>
        Płatności
      </button>
      <button onClick={() => setTab(6)} className={tab === 6 ? "current" : ""}>
        Dostawa
      </button>
      <button onClick={() => setTab(7)} className={tab === 7 ? "current" : ""}>
        Rękojmia
      </button>
      <button onClick={() => setTab(8)} className={tab === 8 ? "current" : ""}>
        Gwarancja
      </button>
      <button onClick={() => setTab(9)} className={tab === 9 ? "current" : ""}>
        Odstąpienie od Umowy sprzedaży
      </button>
      <button
        onClick={() => setTab(10)}
        className={tab === 10 ? "current" : ""}
      >
        Usługi nieodpłatne
      </button>
      <button
        onClick={() => setTab(11)}
        className={tab === 11 ? "current" : ""}
      >
        Odpowiedzialność Klienta w zakresie zamieszczanych przez niego treści
      </button>
      <button
        onClick={() => setTab(12)}
        className={tab === 12 ? "current" : ""}
      >
        Zgłaszanie zagrożenia lub naruszenia praw
      </button>
      <button
        onClick={() => setTab(13)}
        className={tab === 13 ? "current" : ""}
      >
        Ochrona danych osobowych
      </button>
      <button
        onClick={() => setTab(14)}
        className={tab === 14 ? "current" : ""}
      >
        Rozwiązanie umowy (nie dotyczy Umów sprzedaży)
      </button>
      <button onClick={() => setTab(15)} className={tab === 15 && "current"}>
        Postanowienia końcowe
      </button>
    </div>
  )
}

export default PolicyNav
