import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab3 = () => {
  return (
    <>
      <PageSubHeader title="Rejestracja" />
      <ol>
        <li>
          W celu utworzenia Konta Klienta, Klient obowiązany jest dokonać
          nieodpłatnej Rejestracji.
        </li>
        <li>
          Rejestracja nie jest konieczna do złożenia zamówienia w Sklepie
          Internetowym.
        </li>
        <li>
          W celu Rejestracji, Klient powinien wypełnić formularz rejestracyjny
          udostępniony przez Sprzedawcę na Stronie Internetowej Sklepu i
          przesłać wypełniony formularz rejestracyjny drogą elektroniczną do
          Sprzedawcy poprzez wybór odpowiedniej funkcji znajdującej się w
          formularzu rejestracyjnym. Podczas Rejestracji Klient ustala
          indywidualne Hasło.
        </li>
        <li>
          W trakcie wypełniania formularza rejestracyjnego, Klient ma możliwość
          zapoznania się z Regulaminem, akceptując jego treść poprzez oznaczenie
          odpowiedniego pola w formularzu.
        </li>
        <li>
          W trakcie Rejestracji Klient może dobrowolnie wyrazić zgodę na
          przetwarzanie swoich danych osobowych w celach marketingowych poprzez
          zaznaczenie odpowiedniego pola formularza rejestracyjnego. W takim
          przypadku Sprzedawca wyraźnie informuje o celu zbierania danych
          osobowych Klienta, a także o znanych Sprzedawcy lub przewidywanych
          odbiorcach tych danych
        </li>
        <li>
          Wyrażenie przez Klienta zgody na przetwarzanie jego danych osobowych w
          celach marketingowych nie warunkuje możliwości zawarcia ze Sprzedawcą
          umowy o świadczenie drogą elektroniczną usługi Prowadzenie Konta
          Klienta. Zgoda może być w każdej chwili cofnięta, poprzez złożenie
          Sprzedawcy stosownego oświadczenia Klienta. Oświadczenie może zostać
          przykładowo przesłane na adres Sprzedawcy za pośrednictwem poczty
          elektronicznej.
        </li>
        <li>
          Po przesłaniu wypełnionego formularza rejestracyjnego Klient otrzymuje
          niezwłocznie, drogą elektroniczną na adres poczty elektronicznej
          podany w formularzu rejestracyjnym potwierdzenie Rejestracji przez
          Sprzedawcę. Z tą chwilą zawarta zostaje umowa o świadczenie drogą
          elektroniczną usługi Prowadzenie Konta Klienta, zaś Klient uzyskuje
          możliwość dostępu do Konta Klienta i dokonywania zmian podanych
          podczas Rejestracji danych.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab3
