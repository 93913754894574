import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab10 = () => {
  return (
    <>
      <PageSubHeader title="Usługi nieodpłatne" />
      <ol>
        <li>
          Sprzedawca świadczy na rzecz Klientów, drogą elektroniczną usługi
          nieodpłatne:
        </li>
        <ol>
          <li>Newsletter;</li>
          <li>Prowadzenie Konta Klienta;</li>
          <li>Zapytaj o produkt;</li>
          <li>Zamieszczanie opinii.</li>
        </ol>
        <li>
          Usługi wskazane w §10 ust. 1 powyżej świadczone są 7 dni w tygodniu,
          24 godziny na dobę.
        </li>
        <li>
          Sprzedawca zastrzega sobie możliwość wyboru i zmiany rodzaju, form,
          czasu oraz sposobu udzielania dostępu do wybranych wymienionych usług,
          o czym poinformuje Klientów w sposób właściwy dla zmiany Regulaminu.
        </li>
        <li>
          Z usługi Newsletter może skorzystać każdy Klient, który wprowadzi swój
          adres poczty elektronicznej, wykorzystując w tym celu formularz
          rejestracyjny udostępniony przez Sprzedawcę na Stronie Internetowej
          Sklepu. Po przesłaniu wypełnionego formularza rejestracyjnego, Klient
          otrzymuje niezwłocznie, drogą elektroniczną na adres poczty
          elektronicznej podany w formularzu rejestracyjnym link aktywacyjny, w
          celu potwierdzenia zapisania się na subskrypcję Newsletter. Z chwilą
          aktywacji linku przez Klienta zawarta zostaje umowa o świadczenie
          drogą elektroniczną usługi Newsletter. Klient może dodatkowo podczas
          Rejestracji zaznaczyć odpowiednie pole w formularzu rejestracyjnym w
          celu subskrypcji usługi Newsletter.
        </li>
        <li>
          Usługa Newsletter polega na przesyłaniu przez Sprzedawcę, na adres
          poczty elektronicznej, wiadomości w formie elektronicznej zawierającej
          informacje o nowych produktach lub usługach w ofercie Sprzedawcy.
          Newsletter przesyłany jest przez Sprzedawcę do wszystkich Klientów,
          którzy dokonali subskrypcji.
        </li>
        <li>
          Każdy Newsletter kierowany do danych Klientów zawiera w szczególności:
          informację o nadawcy, wypełnione pole „temat”, określające treść
          przesyłki oraz informację o możliwości i sposobie rezygnacji z usługi
          nieodpłatnej Newsletter.
        </li>
        <li>
          Klient może w każdej chwili zrezygnować z otrzymywania Newsletter’a
          przez wypisanie się z subskrypcji za pośrednictwem odnośnika
          zamieszczonego w każdej wiadomości elektronicznej wysłanej w ramach
          usługi Newsletter lub za pośrednictwem dezaktywacji odpowiedniego pola
          w Koncie Klienta.
        </li>
        <li>
          Usługa Zapytaj o produkt, polega na wysłaniu za pomocą formularza
          umieszczonego na Stronie Internetowej Sklepu wiadomości do Sprzedawcy.
        </li>
        <li>
          Rezygnacja z usługi nieodpłatnej Zapytaj o produkt, możliwa jest w
          każdej chwili i polega na zaprzestaniu wysyłania zapytań do
          Sprzedawcy.
        </li>
        <li>
          Usługa Prowadzenie Konta Klienta dostępna jest po dokonaniu
          Rejestracji na zasadach opisanych w Regulaminie i polega na
          udostępnieniu Klientowi dedykowanego panelu w ramach Strony
          Internetowej Sklepu, umożliwiającego Klientowi modyfikacje danych,
          które podał podczas Rejestracji, jak też śledzenia stanu realizacji
          zamówień oraz historii zamówień już zrealizowanych.
        </li>
        <li>
          Klient, który dokonał Rejestracji może zgłosić Sprzedawcy żądanie
          usunięcia Konta Klienta, przy czym w przypadku zgłoszenia żądania
          usunięcia Konta Klienta przez Sprzedawcę, może ono zostać usunięte do
          14 dni od zgłoszenia żądania.
        </li>
        <li>
          Usługa Zamieszczanie opinii polega na umożliwieniu przez Sprzedawcę,
          Klientom posiadającym Konto Klienta publikacji na Stronie Internetowej
          Sklepu indywidualnych i subiektywnych wypowiedzi Klienta dotyczących w
          szczególności Towarów.
        </li>
        <li>
          Rezygnacja z usługi Zamieszczanie opinii możliwa jest w każdej chwili
          i polega na zaprzestaniu zamieszczania treści przez Klienta na Stronie
          Internetowej Sklepu.
        </li>
        <li>
          Sprzedawca jest uprawniony do zablokowania dostępu do Konta Klienta i
          usług nieodpłatnych, w przypadku działania przez Klienta na szkodę
          Sprzedawcy, tj. prowadzenia działalności reklamowej innego
          przedsiębiorcy lub produktu; działalności polegającej na zamieszczaniu
          treści nie związanych z działalnością Sprzedawcy; działalności
          polegającej na zamieszczaniu treści nieprawdziwych lub wprowadzających
          w błąd, jak również w przypadku działania przez Klienta na szkodę
          innych Klientów, naruszenia przez Klienta przepisów prawa lub
          postanowień Regulaminu, a także, gdy zablokowanie dostępu do Konta
          Klienta i usług nieodpłatnych jest uzasadnione względami
          bezpieczeństwa - w szczególności: przełamywaniem przez Klienta
          zabezpieczeń Strony Internetowej Sklepu lub innymi działaniami
          hakerskimi. Zablokowanie dostępu do Konta Klienta i usług
          nieodpłatnych z wymienionych przyczyn trwa przez okres niezbędny do
          rozwiązania kwestii stanowiącej podstawę zablokowania dostępu do Konta
          Klienta i usług nieodpłatnych. Sprzedawca zawiadamia Klienta o
          zablokowaniu dostępu do Konta Klienta i usług nieodpłatnych drogą
          elektroniczną na adres podany przez Klienta w formularzu
          rejestracyjnym.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab10
