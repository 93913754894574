import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab15 = () => {
  return (
    <>
      <PageSubHeader title="Postanowienia końcowe" />
      <ol>
        <li>
          Sprzedawca ponosi odpowiedzialność z tytułu niewykonania lub
          nienależytego wykonania umowy, lecz w przypadku umów zawieranych z
          Klientami będącymi Przedsiębiorcami Sprzedawca ponosi odpowiedzialność
          tylko w przypadku umyślnego wyrządzenia szkody i w granicach
          rzeczywiście poniesionych strat przez Klienta będącego Przedsiębiorcą.
        </li>
        <li>
          Treść niniejszego Regulaminu może zostać utrwalona poprzez
          wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili ze
          Strony Internetowej Sklepu.
        </li>
        <li>
          W przypadku powstania sporu na gruncie zawartej Umowy sprzedaży,
          strony będą dążyły do rozwiązania sprawy polubownie. Prawem właściwym
          dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego
          Regulaminu jest prawo polskie.
        </li>
        <li>
          Sprzedawca informuje Klienta będącego Konsumentem o możliwości
          skorzystania z pozasądowych sposobów rozpatrywania reklamacji i
          dochodzenia roszczeń. Zasady dostępu do tych procedur dostępne są w
          siedzibach lub na stronach internetowych podmiotów uprawnionych do
          pozasądowego rozpatrywania sporów. Mogą nimi być w szczególności
          rzecznicy praw konsumenta lub Wojewódzkie Inspektoraty Inspekcji
          Handlowej, których lista jest dostępna na stronie internetowej Urzędu
          Ochrony Konkurencji i Konsumentów. Sprzedawca informuje, że pod
          adresem http://ec.europa.eu/consumers/odr/ dostępna jest platforma
          internetowego systemu rozstrzygania sporów pomiędzy konsumentami i
          przedsiębiorcami na szczeblu unijnym (platforma ODR).
        </li>
        <li>
          Sprzedawca zastrzega sobie prawo zmiany niniejszego Regulaminu.
          Wszystkie zamówienia przyjęte przez Sprzedawcę do realizacji przed
          dniem wejścia w życie nowego Regulaminu są realizowane na podstawie
          Regulaminu, który obowiązywał w dniu składania zamówienia przez
          Klienta. Zmiana Regulaminu wchodzi w życie w terminie 7 dni od dnia
          opublikowania na Stronie Internetowej Sklepu. Sprzedawca poinformuje
          Klienta na 7 dni przed wejściem w życie nowego Regulaminu o zmianie
          Regulaminu za pomocą wiadomości przesłanej drogą elektroniczną
          zawierającej odnośnik do tekstu zmienionego Regulaminu. W razie, gdy
          Klient nie akceptuje nowej treści Regulaminu obowiązany jest
          zawiadomić o tym fakcie Sprzedawcę, co skutkuje rozwiązaniem umowy
          zgodnie z postanowieniami §14 Regulaminu.
        </li>
        <li>Umowy ze Sprzedawcą zawierane są w języku polskim.</li>
        <li>Regulamin wchodzi w życie z dniem 26.11.2021 r.</li>
      </ol>
    </>
  )
}

export default PolicyContentTab15
