import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab6 = () => {
  return (
    <>
      <PageSubHeader title="Dostawa" />
      <ol>
        <li>
          Sprzedawca realizuje Dostawę na terytorium Rzeczypospolitej Polskiej.
        </li>
        <li>
          Sprzedawca jest zobowiązany dostarczyć Towar będący przedmiotem Umowy
          sprzedaży bez wad.
        </li>
        <li>
          Sprzedawca zamieszcza na Stronie Internetowej Sklepu informację o
          liczbie Dni roboczych potrzebnych do Dostawy i realizacji zamówienia.
        </li>
        <li>
          Termin Dostawy i realizacji zamówienia wskazany na Stronie
          Internetowej Sklepu liczony jest w Dniach roboczych zgodnie z §5 ust.
          2 Regulaminu.
        </li>
        <li>
          Zamówione Towary są dostarczane do Klienta za pośrednictwem Dostawcy
          na adres wskazany w formularzu zamówienia. W przypadku wyboru InPost
          Sp. z o.o. z siedzibą w Krakowie jako Dostawcy, adresem Dostawy będzie
          adres paczkomatu wybranego przez Klienta w momencie składania
          zamówienia.
        </li>
        <li>
          W dniu wysłania Towaru do Klienta (jeśli nie wybrano możliwości
          osobistego odbioru Towaru) przekazywana jest na adres poczty
          elektronicznej Klienta informacja potwierdzająca nadanie przesyłki
          przez Sprzedawcę.
        </li>
        <li>
          Klient obowiązany jest zbadać doręczoną przesyłkę w czasie i w sposób
          przyjęty przy przesyłkach danego rodzaju. W razie stwierdzenia ubytku
          lub uszkodzenia przesyłki Klient ma prawo żądać od pracownika Dostawcy
          spisania właściwego protokołu.
        </li>
        <li>
          Klient ma możliwość odbioru osobistego zamówionego Towaru. Odbioru
          można dokonać w Salonie Stacjonarnym w Dni Robocze, w godzinach
          otwarcia wskazanych na Stronie Internetowej Sklepu, po wcześniejszym
          ustaleniu ze Sprzedawcą terminu odbioru za pośrednictwem poczty
          elektronicznej lub telefonicznie.
        </li>
        <li>
          Sprzedawca, zgodnie z wolą Klienta, dołącza do przesyłki będącej
          przedmiotem Dostawy paragon albo fakturę VAT obejmującą dostarczane
          Towary. Klient w celu otrzymania faktury VAT powinien zadeklarować w
          momencie dokonywania zakupu, że nabywa Towar jako Przedsiębiorca
          (podatnik). Zgłoszenie powyższej deklaracji następuje poprzez
          oznaczenie odpowiedniego pola w formularzu zamówienia, przed wysłaniem
          zamówienia do Sprzedawcy.
        </li>
        <li>
          W przypadku nieobecności Klienta pod wskazanym przez niego adresem,
          podanym przy składaniu zamówienia jako adres Dostawy, pracownik
          Dostawcy pozostawi awizo lub podejmie próbę kontaktu telefonicznego
          celem ustalenia terminu, w którym Klient będzie obecny. W przypadku
          zwrotnego odesłania zamówionego Towaru do Sklepu Internetowego przez
          Dostawcę, Sprzedawca skontaktuje się z Klientem drogą elektroniczną
          lub telefonicznie, ustalając ponownie z Klientem termin i koszt
          Dostawy.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab6
