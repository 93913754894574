import "styles/pages/privacy-policy.scss"

import React, { useState } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import { PolicyNav, PolicyContent } from "components/Legals/Policy"

const Policy = () => {
  const title = "Regulamin"
  const [tab, setTab] = useState(1)

  const policyData = {
    tab,
    setTab,
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="privacy-policy">
        <div className="container-fluid">
          <PageHeader title={title} />
          <PageSubHeader title="Wstęp" />
          <p>
            Niniejszy Regulamin określa ogólne warunki, zasady oraz sposób
            sprzedaży prowadzonej przez MEMTECH Sp. z o.o. Sp. k. z siedzibą w
            Warszawie, za pośrednictwem sklepu internetowego www.memtech.pl
            (zwanego dalej: „Sklepem Internetowym”) oraz określa zasady i
            warunki świadczenia przez MEMTECH Sp. z o.o. Sp. k. z siedzibą w
            Warszawie usług nieodpłatnych drogą elektroniczną.
          </p>

          <div className="row">
            <div className="col-md-4">
              <PolicyNav {...policyData} />
            </div>
            <div className="col-md-8">
              <PolicyContent {...policyData} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Policy
