import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab1 = () => {
  return (
    <>
      <PageSubHeader title="Definicje" />
      <ol>
        <li>
          <strong>Dni robocze</strong> - oznacza dni tygodnia od poniedziałku do
          piątku z wyłączeniem dni ustawowo wolnych od pracy.
        </li>
        <li>
          <strong>Dostawa</strong> - oznacza czynność faktyczną polegającą na
          dostarczeniu Klientowi przez Sprzedawcę, za pośrednictwem Dostawcy,
          Towaru określonego w zamówieniu.
        </li>
        <ol>
          <li>firmę kurierską;</li>
          <li>
            InPost Sp. z o.o. z siedzibą w Krakowie, świadcząca usługi Dostawy i
            obsługi systemu skrytek pocztowych (Paczkomat).
          </li>
        </ol>
        <li>
          <strong>Hasło</strong> - oznacza ciąg znaków literowych, cyfrowych lub
          innych wybranych przez Klienta podczas Rejestracji w Sklepie
          Internetowym, wykorzystywanych w celu zabezpieczenia dostępu do Konta
          Klienta w Sklepie Internetowym.
        </li>
        <li>
          <strong>Klient</strong> - oznacza podmiot, na rzecz którego zgodnie z
          Regulaminem i przepisami prawa mogą być świadczone usługi drogą
          elektroniczną lub z którym zawarta może być Umowa sprzedaży
        </li>
        <li>
          <strong>Konsument</strong> -oznacza osobę fizyczną dokonującą z
          przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej
          działalnością gospodarczą lub zawodową.
        </li>
        <li>
          <strong>Konto Klienta</strong> - oznacza indywidualny dla każdego
          Klienta panel, uruchomiony na jego rzecz przez Sprzedawcę, po
          dokonaniu przez Klienta Rejestracji i zawarciu umowy świadczenia
          usługi Prowadzenie Konta Klienta.
        </li>
        <li>
          <strong>Przedsiębiorca</strong> - oznacza osobę fizyczną, osobę prawną
          lub jednostkę organizacyjną niebędącą osobą prawną, której ustawa
          przyznaje zdolność prawną, prowadzącą we własnym imieniu działalność
          gospodarczą lub zawodową i dokonującą czynności prawnej związanej
          bezpośrednio z jej działalnością gospodarczą lub zawodową.
        </li>
        <li>
          <strong>Przedsiębiorca z uprawnieniami Konsumenta</strong> - oznacza
          osobę fizyczną zawierającą Umowę sprzedaży bezpośrednio związaną z jej
          działalnością gospodarczą, gdy z treści Umowy sprzedaży wynika, że nie
          posiada ona dla tego Przedsiębiorcy zawodowego charakteru,
          wynikającego w szczególności z przedmiotu wykonywanej przez niego
          działalności gospodarczej, udostępnionego na podstawie przepisów o
          Centralnej Ewidencji i Informacji o Działalności Gospodarczej.
        </li>
        <li>
          <strong>Regulamin</strong> - oznacza niniejszy regulamin.
        </li>
        <li>
          <strong>Rejestracja</strong> - oznacza czynność faktyczną dokonaną w
          sposób określony w Regulaminie, wymaganą dla korzystania przez Klienta
          ze wszystkich funkcjonalności Sklepu Internetowego.
        </li>
        <li>
          <strong>Salon Stacjonarny</strong> - oznacza miejsce przeznaczone do
          obsługi Klientów, pod adresem: MEMTECH Sp. z o.o. Sp. k., ul.
          Doroszewskiego 7, 01-318 Warszawa.
        </li>
        <li>
          <strong>Sprzedawca</strong> - oznacza MEMTECH Sp. z o.o. Sp. k. z
          siedzibą w Warszawie (01-318), ul. Witolda Doroszewskiego 7, NIP:
          7262655349, REGON: 360051116, wpisaną do rejestru przedsiębiorców
          prowadzonego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX
          Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS
          0000530828; e-mail: serwis@memtech.pl, będącą jednocześnie
          właścicielem Sklepu Internetowego, reprezentowana przez
          komplementariusza: Memtech sp. z o.o. z siedzibą w Warszawie (01-318),
          ul. Witolda Doroszewskiego 7, wpisana do rejestru przedsiębiorców
          prowadzonego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX
          Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS
          0000305587 o kapitale zakładowym w wysokości 50.000 zł, posiadająca
          numer NIP: 7262655332, numer Regon: 360127300. Numer BDO - 000143949.
        </li>
        <li>
          <strong>Strona Internetowa Sklepu</strong> - oznacza strony
          internetowe, pod którymi Sprzedawca prowadzi Sklep Internetowy,
          działające w domenie www.memtech.pl.
        </li>
        <li>
          <strong>Towar</strong> - oznacza produkt przedstawiony przez
          Sprzedawcę za pośrednictwem Strony Internetowej Sklepu, mogący być
          przedmiotem Umowy sprzedaży. Każdy prezentowany przez Sprzedawcę towar
          jest oznaczony jako nowy, pochodzący z ekspozycji lub używany. W
          przypadku Towarów używanych lub pochodzących z ekspozycji Sprzedawca
          każdorazowo informuje Klienta o ewentualnych widocznych śladach
          użytkowania.
        </li>
        <li>
          <strong>Trwały nośnik</strong> - oznacza materiał lub narzędzie
          umożliwiające Klientowi lub Sprzedawcy przechowywanie informacji
          kierowanych osobiście do niego, w sposób umożliwiający dostęp do
          informacji w przyszłości przez czas odpowiedni do celów, jakim te
          informacje służą, i które pozwalają na odtworzenie przechowywanych
          informacji w niezmienionej postaci.
        </li>
        <li>
          <strong>Umowa sprzedaży</strong> - oznacza umowę sprzedaży zawartą na
          odległość, na zasadach określonych w Regulaminie, między Klientem a
          Sprzedawcą.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab1
