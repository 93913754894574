import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab12 = () => {
  return (
    <>
      <PageSubHeader title="Zgłaszanie zagrożenia lub naruszenia praw" />
      <ol>
        <li>
          W przypadku, gdy Klient lub inna osoba lub podmiot uzna, iż treść
          publikowana na Stronie Internetowej Sklepu narusza ich prawa, dobra
          osobiste, dobre obyczaje, uczucia, moralność, przekonania, zasady
          uczciwej konkurencji, know-how, tajemnicę chronioną prawem lub na
          podstawie zobowiązania, może powiadomić Sprzedawcę o potencjalnym
          naruszeniu.
        </li>
        <li>
          Sprzedawca powiadomiony o potencjalnym naruszeniu, podejmuje
          niezwłoczne działania mające na celu usunięcie ze Strony Internetowej
          Sklepu, treści będących przyczyną naruszenia.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab12
