import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab7 = () => {
  return (
    <>
      <PageSubHeader title="Rękojmia" />
      <ol>
        <li>
          Sprzedawca zapewnia Dostawę Towaru pozbawionego wad fizycznych i
          prawnych. Sprzedawca jest odpowiedzialny względem Klienta, jeżeli
          Towar ma wadę fizyczną lub prawną (rękojmia).
        </li>
        <li>Jeżeli Towar ma wadę, Klient może:</li>
        <ol>
          <li>
            złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od Umowy
            sprzedaży, chyba że Sprzedawca niezwłocznie i bez nadmiernych
            niedogodności dla Klienta wymieni Towar wadliwy na wolny od wad albo
            wadę usunie. Ograniczenie to nie ma zastosowania, jeżeli Towar był
            już wymieniony lub naprawiany przez Sprzedawcę albo Sprzedawca nie
            uczynił zadość obowiązkowi wymiany Towaru na wolny od wad lub
            usunięcia wad. Klient może zamiast zaproponowanego przez Sprzedawcę
            usunięcia wady żądać wymiany Towaru na wolny od wad albo zamiast
            wymiany Towaru żądać usunięcia wady, chyba że doprowadzenie rzeczy
            do zgodności z umową w sposób wybrany przez Klienta jest niemożliwe
            albo wymagałoby nadmiernych kosztów w porównaniu ze sposobem
            proponowanym przez Sprzedawcę. Przy ocenie nadmierności kosztów
            uwzględnia się wartość Towaru wolnego od wad, rodzaj i znaczenie
            stwierdzonej wady, a także bierze się pod uwagę niedogodności, na
            jakie narażałby Klienta inny sposób zaspokojenia. Klient nie może
            odstąpić od Umowy sprzedaży, jeżeli wada jest nieistotna.
          </li>
          <li>
            żądać wymiany Towaru wadliwego na wolny od wad albo usunięcia wady.
            Sprzedawca jest obowiązany wymienić Towar wadliwy na wolny od wad
            lub usunąć wadę w rozsądnym czasie bez nadmiernych niedogodności dla
            Klienta. Sprzedawca może odmówić zadośćuczynienia żądaniu Klienta,
            jeżeli doprowadzenie do zgodności z Umową sprzedaży Towaru wadliwego
            w sposób wybrany przez Klienta jest niemożliwe lub w porównaniu z
            drugim możliwym sposobem doprowadzenia do zgodności z Umową
            sprzedaży wymagałoby nadmiernych kosztów. Koszty naprawy lub wymiany
            ponosi Sprzedawca.
          </li>
        </ol>
        <li>
          Klient, który wykonuje uprawnienia z tytułu rękojmi, jest obowiązany
          dostarczyć rzecz wadliwą na adres Sprzedawcy. W wypadku Klienta
          będącego Konsumentem oraz Przedsiębiorcy z uprawnieniami Konsumenta,
          koszt dostarczenia pokrywa Sprzedawca.
        </li>
        <li>
          Sprzedawca odpowiada z tytułu rękojmi, jeżeli wada fizyczna zostanie
          stwierdzona przed upływem dwóch lat od wydania Towaru Klientowi.
          Roszczenie o usunięcie wady lub wymianę Towaru na wolny od wad
          przedawnia się z upływem roku, lecz termin ten nie może zakończyć się
          przed upływem terminu określonego w zdaniu pierwszym. W tym terminie
          Klient może odstąpić od Umowy sprzedaży lub złożyć oświadczenie o
          obniżeniu ceny z powodu wady Towaru. Jeżeli Klient żądał wymiany
          Towaru na wolny od wad lub usunięcia wady, termin do odstąpienia od
          Umowy sprzedaży lub złożenia oświadczenia o obniżeniu ceny rozpoczyna
          się z chwilą bezskutecznego upływu terminu do wymiany Towaru lub
          usunięcia wady. W przypadku Towaru, który był oznaczony przez
          Sprzedawcę jako używany, Sprzedawca odpowiada z tytułu rękojmi, jeżeli
          wada fizyczna zostanie stwierdzona przed upływem jednego roku od
          wydania Towaru Klientowi.
        </li>
        <li>
          Wszelkie reklamacje związane z Towarem lub realizacją Umowy sprzedaży,
          Klient może kierować w formie pisemnej na adres Sprzedawcy.
        </li>
        <li>
          Sprzedawca w ciągu 14 dni od dnia żądania zawierającego reklamację,
          ustosunkuje się do reklamacji Towaru lub reklamacji związanej z
          realizacją Umowy sprzedaży zgłoszonej przez Klienta.
        </li>
        <li>
          Klient może zgłosić Sprzedawcy reklamację w związku z korzystaniem z
          usług nieodpłatnych świadczonych drogą elektroniczną przez Sprzedawcę.
          Reklamacja może być złożona w formie elektronicznej i przesłana na
          adres serwis@memtech.pl. W zgłoszeniu reklamacyjnym Klient winien
          zawrzeć opis zaistniałego problemu. Sprzedawca niezwłocznie, lecz nie
          później niż w terminie 14 dni rozpatruje reklamacje i udziela
          Klientowi odpowiedzi.
        </li>
        <li>
          Sprzedawca nie korzysta z pozasądowego rozwiązywania sporów, o których
          mowa w ustawie z dnia 23 września 2016 r., o pozasądowym rozwiązywaniu
          sporów konsumenckich.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab7
