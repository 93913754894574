import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab8 = () => {
  return (
    <>
      <PageSubHeader title="Gwarancja" />
      <ol>
        <li>
          Towary sprzedawane przez Sprzedawcę mogą być objęte gwarancją
          udzieloną przez producenta Towaru bądź dystrybutora.
        </li>
        <li>
          W wypadku Towarów objętych gwarancją, informacja dotycząca istnienia i
          treści gwarancji jest każdorazowo prezentowana na Stronie Internetowej
          Sklepu.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab8
