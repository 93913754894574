import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab14 = () => {
  return (
    <>
      <PageSubHeader title="Rozwiązanie umowy (nie dotyczy Umów sprzedaży)" />
      <ol>
        <li>
          Zarówno Klient, jak i Sprzedawca mogą rozwiązać umowę o świadczenie
          usług drogą elektroniczną w każdym czasie i bez podania przyczyn, z
          zastrzeżeniem zachowania praw nabytych przez drugą stronę przed
          rozwiązaniem ww. umowy oraz postanowień poniżej.
        </li>
        <li>
          Klient, który dokonał Rejestracji rozwiązuje umowę o świadczenie usług
          drogą elektroniczną poprzez wysłanie do Sprzedawcy stosownego
          oświadczenia woli, przy użyciu dowolnego środka komunikacji na
          odległość, umożliwiającego zapoznanie się Sprzedawcy z oświadczeniem
          woli Klienta.
        </li>
        <li>
          Sprzedawca wypowiada umowę o świadczenie usług drogą elektroniczną
          poprzez wysłanie do Klienta stosownego oświadczenia woli na adres
          poczty elektronicznej podany przez Klienta podczas Rejestracji.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab14
