import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab2 = () => {
  return (
    <>
      <PageSubHeader title="Postanowienia ogólne i korzystanie ze Sklepu Internetowego" />
      <ol>
        <li>
          Wszelkie prawa do Sklepu Internetowego, w tym majątkowe prawa
          autorskie, prawa własności intelektualnej do jego nazwy, jego domeny
          internetowej, Strony Internetowej Sklepu, a także do wzorców,
          formularzy, logotypów zamieszczanych na Stronie Internetowej Sklepu (z
          wyjątkiem logotypów i zdjęć prezentowanych na Stronie Internetowej
          Sklepu w celach prezentacji towarów, do których to prawa autorskie
          należą do podmiotów trzecich) należą do Sprzedawcy, a korzystanie z
          nich może następować wyłącznie w sposób określony i zgodny z
          Regulaminem oraz za zgodą Sprzedawcy wyrażoną na piśmie.
        </li>
        <li>
          Sprzedawca dołoży starań, aby korzystanie ze Sklepu Internetowego było
          możliwe dla użytkowników Internetu z użyciem wszystkich popularnych
          przeglądarek internetowych, systemów operacyjnych, typów urządzeń oraz
          typów połączeń internetowych. Minimalne wymagania techniczne
          umożliwiające korzystanie ze Strony Internetowej Sklepu to
          przeglądarka internetowa w wersji co najmniej Internet Explorer 11 lub
          Chrome 89 lub FireFox 86 lub Opera 53 lub Safari 5 lub nowszych, z
          włączoną obsługą języka Javascript, akceptująca pliki typu „cookies”
          oraz łącze internetowe o przepustowości co najmniej 256 kbit/s. Strona
          Internetowa Sklepu jest zoptymalizowana dla minimalnej rozdzielczości
          ekranu 1024x768 pikseli.
        </li>
        <li>
          Sprzedawca stosuje mechanizm plików "cookies", które podczas
          korzystania przez Klientów ze Strony Internetowej Sklepu, zapisywane
          są przez serwer Sprzedawcy na dysku twardym urządzenia końcowego
          Klienta. Stosowanie plików "cookies" ma na celu poprawne działanie
          Strony Internetowej Sklepu na urządzeniach końcowych Klientów.
          Mechanizm ten nie niszczy urządzenia końcowego Klienta oraz nie
          powoduje zmian konfiguracyjnych w urządzeniach końcowych Klientów ani
          w oprogramowaniu zainstalowanym na tych urządzeniach. Każdy Klient
          może wyłączyć mechanizm „cookies” w przeglądarce internetowej swojego
          urządzenia końcowego. Sprzedawca wskazuje, że wyłączenie „cookies”
          może jednak spowodować utrudnienia lub uniemożliwić korzystanie ze
          Strony Internetowej Sklepu.
        </li>
        <li>
          W celu złożenia zamówienia w Sklepie Internetowym za pośrednictwem
          Strony Internetowej Sklepu lub za pośrednictwem poczty elektronicznej
          oraz w celu korzystania z usług dostępnych na Stronach Internetowych
          Sklepu, konieczne jest posiadanie przez Klienta aktywnego konta poczty
          elektronicznej.
        </li>
        <li>
          Zakazane jest dostarczanie przez Klienta treści o charakterze
          bezprawnym oraz wykorzystywanie przez Klienta Sklepu Internetowego,
          Strony Internetowej Sklepu lub usług nieodpłatnych świadczonych przez
          Sprzedawcę, w sposób sprzeczny z prawem, dobrymi obyczajami lub
          naruszający dobra osobiste osób trzecich.
        </li>
        <li>
          Sprzedawca oświadcza, iż publiczny charakter sieci Internet i
          korzystanie z usług świadczonych drogą elektroniczną wiązać może się z
          zagrożeniem pozyskania i modyfikowania danych Klientów przez osoby
          nieuprawnione, dlatego Klienci powinni stosować właściwe środki
          techniczne, które zminimalizują wskazane wyżej zagrożenia. W
          szczególności powinni stosować programy antywirusowe i chroniące
          tożsamość korzystających z sieci Internet. Sprzedawca nigdy nie zwraca
          się do Klienta z prośbą o udostępnienie mu w jakiejkolwiek formie
          Hasła.
        </li>
        <li>
          Nie jest dopuszczalne wykorzystywanie zasobów i funkcji Sklepu
          Internetowego w celu prowadzenia przez Klienta działalności, która
          naruszałaby interes Sprzedawcy, tj. działalności reklamowej innego
          przedsiębiorcy lub produktu; działalności polegającej na zamieszczaniu
          treści niezwiązanych z działalnością Sprzedawcy; działalności
          polegającej na zamieszczaniu treści nieprawdziwych lub wprowadzających
          w błąd.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab2
