import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab5 = () => {
  return (
    <>
      <PageSubHeader title="Płatności" />
      <ol>
        <li>
          Ceny na Stronie Internetowej Sklepu zamieszczone przy danym Towarze
          stanowią ceny brutto i nie zawierają informacji odnośnie kosztów
          Dostawy i wszelkich innych kosztów, które Klient będzie zobowiązany
          ponieść w związku z Umową sprzedaży, o których Klient będzie
          poinformowany przy wyborze sposobu Dostawy i składaniu zamówienia.
        </li>
        <li>
          Klient może wybrać następujące formy płatności za zamówione Towary:
        </li>
        <ol>
          <li>
            przelew bankowy na rachunek bankowy Sprzedawcy (w tym przypadku
            realizacja zamówienia rozpoczęta zostanie po przesłaniu Klientowi
            przez Sprzedawcę potwierdzenia przyjęcia zamówienia oraz po
            wpłynięciu środków na rachunek bankowy Sprzedawcy);
          </li>
          <li>
            przelew bankowy na rachunek bankowy Sprzedawcy z opcją odbioru
            osobistego w Salonie Stacjonarnym (w tym przypadku realizacja
            zamówienia zostanie rozpoczęta niezwłocznie po przesłaniu Klientowi
            przez Sprzedawcę potwierdzenia przyjęcia zamówienia, zaś Towar
            wydany zostanie w Salonie Stacjonarnym, po wpłynięciu środków na
            rachunek bankowy Sprzedawcy);
          </li>
          <li>
            karta płatnicza lub przelew bankowy poprzez zewnętrzny system
            płatności przelewy24.pl, obsługiwany przez firmę PayPro S.A. z
            siedzibą w Poznaniu (w tym przypadku realizacja zamówienia
            rozpoczęta zostanie po przesłaniu Klientowi przez Sprzedawcę
            potwierdzenia przyjęcia zamówienia oraz po wpłynięciu środków na
            rachunek bankowy Sprzedawcy);
          </li>
          <li>
            gotówką za pobraniem, płatność Dostawcy przy dokonywaniu Dostawy (w
            tym przypadku realizacja zamówienia zostanie rozpoczęta po
            przesłaniu Klientowi przez Sprzedawcę potwierdzenia przyjęcia
            zamówienia);
          </li>
          <li>
            gotówką przy odbiorze osobistym – płatność w Salonie Stacjonarnym (w
            tym przypadku realizacja zamówienia zostanie dokonana niezwłocznie
            po przesłaniu Klientowi przez Sprzedawcę potwierdzenia przyjęcia
            zamówienia, zaś Towar wydany zostanie w Salonie Stacjonarnym).
          </li>
        </ol>
        <li>
          Klient powinien dokonać płatności za zamówienie w wysokości
          wynikającej z zawartej Umowy sprzedaży w terminie 7 Dni roboczych,
          jeżeli wybrał formę przedpłaty.
        </li>
        <li>
          W wypadku niewywiązania się przez Klienta z płatności w terminie, o
          którym mowa w §5 ust. 3 Regulaminu, Sprzedawca wyznacza Klientowi
          dodatkowy termin na dokonanie płatności i informuje o nim Klienta na
          Trwałym nośniku. Informacja o dodatkowym terminie na dokonanie
          płatności zawiera również informację, że po bezskutecznym upływie tego
          terminu, Sprzedawca odstąpi od Umowy sprzedaży. W wypadku
          bezskutecznego upływu drugiego terminu na dokonanie płatności,
          Sprzedawca prześle Klientowi na Trwałym nośniku oświadczenie o
          odstąpieniu od umowy na podstawie art. 491 Kodeksu Cywilnego.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab5
