import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const PolicyContentTab4 = () => {
  return (
    <>
      <PageSubHeader title="Zamówienia" />
      <ol>
        <li>
          Informacje zawarte na Stronie Internetowej Sklepu nie stanowią oferty
          Sprzedawcy w rozumieniu Kodeksu Cywilnego, a jedynie zaproszenie
          Klientów do składania ofert zawarcia Umowy sprzedaży.
        </li>
        <li>
          Klient może składać zamówienia w Sklepie Internetowym za pośrednictwem
          Strony Internetowej Sklepu lub poczty elektronicznej przez 7 dni w
          tygodniu, 24 godziny na dobę.
        </li>
        <li>
          Klient składający zamówienie za pośrednictwem Strony Internetowej
          Sklepu, kompletuje zamówienie wybierając Towar, którym jest
          zainteresowany. Dodanie Towaru do zamówienia następuje przez wybór
          polecenia "DODAJ DO KOSZYKA" pod danym Towarem prezentowanym na
          Stronie Internetowej Sklepu. Klient po skompletowaniu całości
          zamówienia i wskazaniu w „KOSZYKU” sposobu Dostawy oraz formy
          płatności, składa zamówienie przez wysłanie formularza zamówienia do
          Sprzedawcy, wybierając na Stronie Internetowej Sklepu przycisk
          „Zamawiam z obowiązkiem zapłaty”. Każdorazowo przed wysyłką zamówienia
          do Sprzedawcy, Klient jest informowany o łącznej cenie za wybrany
          Towar i Dostawę, jak też o wszystkich dodatkowych kosztach jakie jest
          zobowiązany ponieść w związku z Umową sprzedaży.
        </li>
        <li>
          Klient składający zamówienie za pośrednictwem poczty elektronicznej,
          przesyła je na adres e-mail podany przez Sprzedawcę na Stronie
          Internetowej Sklepu. Klient w wiadomości przesyłanej do Sprzedawcy,
          podaje w szczególności: nazwę Towaru, kolor oraz jego ilość, spośród
          Towarów prezentowanych na Stronie Internetowej Sklepu oraz swoje dane
          teleadresowe.
        </li>
        <li>
          Po otrzymaniu od Klienta drogą elektroniczną wiadomości o której mowa
          w §4 ust. 4, Sprzedawca przesyła Klientowi zwrotną wiadomość za
          pośrednictwem poczty elektronicznej, podając swoje dane rejestrowe,
          cenę wybranych Towarów oraz możliwe formy płatności oraz sposób
          Dostawy wraz z jego kosztem, jak też informacje o wszystkich
          dodatkowych płatnościach jakie Klient miałby ponieść z tytułu Umowy
          sprzedaży. Wiadomość zawiera także informację dla Klienta, że zawarcie
          przez niego Umowy sprzedaży za pośrednictwem poczty elektronicznej
          pociąga za sobą obowiązek zapłaty za zamówiony Towar. Na podstawie
          podanych przez Sprzedawcę informacji, Klient może złożyć zamówienie,
          przesyłając wiadomość elektroniczną do Sprzedawcy wskazując wybraną
          formę płatności oraz sposób Dostawy.
        </li>
        <li>
          Złożenie zamówienia stanowi złożenie Sprzedawcy przez Klienta oferty
          zawarcia Umowy sprzedaży Towarów będących przedmiotem zamówienia.
        </li>
        <li>
          Po złożeniu zamówienia, Sprzedawca przesyła na podany przez Klienta
          adres poczty elektronicznej potwierdzenie jego złożenia.
        </li>
        <li>
          Następnie, po potwierdzeniu złożenia zamówienia, Sprzedawca przesyła
          na podany przez Klienta adres poczty elektronicznej informację o
          przyjęciu zamówienia do realizacji. Informacja o przyjęciu zamówienia
          do realizacji jest oświadczeniem Sprzedawcy o przyjęciu oferty, o
          której mowa w §4 ust. 6 powyżej i z chwilą jego otrzymania przez
          Klienta zostaje zawarta Umowa sprzedaży.
        </li>
        <li>
          Po zawarciu Umowy sprzedaży, Sprzedawca potwierdza Klientowi jej
          warunki, przesyłając je na Trwałym nośniku, na adres poczty
          elektronicznej Klienta lub pisemnie na wskazany przez Klienta podczas
          Rejestracji lub składania zamówienia adres.
        </li>
        <li>
          Przy dokonywaniu płatności za nabyte Towary, wymienione w Załączniku
          nr 15 do ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług
          (t.j. Dz.U. 2018 poz. 2174 ze zm.), które to płatności udokumentowane
          są fakturą, w której kwota należności ogółem przekracza 15 000 zł
          brutto lub równowartość tej kwoty - podatnicy są obowiązani zastosować
          mechanizm podzielonej płatności, zgodnie z obowiązującymi przepisami,
          o ile ma on zastosowanie wobec Klienta składającego zamówienie.
          Faktura wystawiona przez Sprzedawcę, o której mowa powyżej, powinna
          zawierać wyrazy: “mechanizm podzielonej płatności”. Strony takiej
          transakcji, obowiązane są posiadać rachunek rozliczeniowy, o którym
          mowa w art. 49 ust. 1 pkt 1 ustawy z dnia 29 sierpnia 1997 r. - Prawo
          bankowe, lub imienny rachunek w spółdzielczej kasie oszczędnościowo-
          kredytowej otwarty w związku z prowadzoną działalnością gospodarczą,
          prowadzone w walucie polskiej.
        </li>
      </ol>
    </>
  )
}

export default PolicyContentTab4
